import React, {useRef, useEffect, useState, useCallback} from 'react'
import SlideFull from "../slide/slide-full";
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {
    MinusIcon,
    PlusIcon
} from "@heroicons/react/outline";
import noMinigoal from "../../resources/images/no-minigoal.png";
import EmptyState from "../layout/empty-state";
import {useTranslation} from "react-i18next";
import LoaderWrapper from "../loader/loader-wrapper";
import {useSelector} from "react-redux";
import { isMobile } from 'react-device-detect';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const PdfSliderViewer = ({isOpen, setIsOpen, url, magazineId = {}, title, thumbnails }) => {

    const viewerRef = useRef();
    const { t } = useTranslation();
    const { detail } = useSelector((state) => state.user );
    const [pdfData, setPdfData] = useState(null); // Per il contenuto del PDF

    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const [visiblePages, setVisiblePages] = useState([1]);
    const pageRefs = useRef([]);

    const [isLoading, setLoading] = useState(true);

    const [scale, setScale] = useState(0); // 100%
    const [zoom, setZoom] = useState(100);


    useEffect(() => {

        if(document.getElementById('zoomPdf') && isOpen && detail.id !== '41d14698-663d-4bf5-a09c-12838a84e335'){
            const zoomPdfEl = document.getElementById('zoomPdf');

            // Disables certain events
            const disableEvent = (event) => {
                zoomPdfEl[`on${event}`] = () => false;
            };

            // Create and append style to head
            const appendStyle = (media, innerHTML) => {
                const cssNode = document.createElement('style');
                cssNode.type = 'text/css';
                cssNode.media = media;
                cssNode.innerHTML = innerHTML;
                document.head.appendChild(cssNode);
            };

            disableEvent('copy');
            disableEvent('contextmenu');
            disableEvent('selectstart');
            disableEvent('drag');

            const spanEl = document.createElement("span");
            spanEl.style.display = "none";
            spanEl.style.position = "absolute";

            const firstChild = zoomPdfEl.firstChild;
            zoomPdfEl.insertBefore(spanEl, firstChild);
            spanEl.style.width = `${zoomPdfEl.scrollWidth}px`;
            spanEl.style.height = `${zoomPdfEl.scrollHeight}px`;
            spanEl.style.display = "block";

            appendStyle('print', 'body{display:none}');
            appendStyle('screen', 'div{-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}');

            const userSelectCSS = "-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;";
            const blurCSS = "-webkit-filter: blur(5px);-moz-filter: blur(5px);-ms-filter: blur(5px);-o-filter: blur(5px);filter: blur(5px);";
            const clearCSS = "-webkit-filter: blur(0px);-moz-filter: blur(0px);-ms-filter: blur(0px);-o-filter: blur(0px);filter: blur(0px);";

            zoomPdfEl.style.cssText = userSelectCSS;

            const toBlur = () => zoomPdfEl.style.cssText = blurCSS;
            const toClear = () => zoomPdfEl.style.cssText = clearCSS;

            zoomPdfEl.onclick = toClear;
            zoomPdfEl.onmouseleave = toBlur;
            zoomPdfEl.onblur = toBlur;

            document.addEventListener('keyup', (e) => {
                if (e.key === 'PrintScreen') {
                    navigator.clipboard.writeText('');
                }

                if (e.altKey && e.key === 'PrintScreen') {
                    navigator.clipboard.writeText('');
                }
            });

            document.addEventListener('keydown', (e) => {
                if (e.ctrlKey && e.key === 'p') {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            });

        }
        // eslint-disable-next-line
    }, [isOpen, document.getElementById('zoomPdf'), detail]);

    useEffect(() => {
            const zoomPdfEl = document.getElementById('zoomPdf');

            if (!zoomPdfEl) {
                return;
            }


            if (detail.id === '41d14698-663d-4bf5-a09c-12838a84e335') {
                return;
            }

            const blurCSS = "-webkit-filter: blur(5px);-moz-filter: blur(5px);-ms-filter: blur(5px);-o-filter: blur(5px);filter: blur(5px);";

            const toBlur = () => {
                zoomPdfEl.style.cssText = blurCSS;
            }


            const isMac = navigator.platform.match("Mac");

            const checkKeyPressed = (e) => {
                if (["44", "91"].includes(e.keyCode.toString()) || e.keyCode === 83) {
                    toBlur();
                    if (e.keyCode === 83) {
                        e.preventDefault();
                        alert("Non puoi salvare");
                    }
                }
            }

            const handleKeydown = (event) => {
                const { keyCode, metaKey, ctrlKey } = event;
                const commandPressed = isMac ? metaKey : ctrlKey;

                if ([44, 91, 17].includes(keyCode) || commandPressed) {
                    toBlur();
                    event.preventDefault();
                    event.stopPropagation();
                }

                if (keyCode === 83 && commandPressed) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                if (keyCode === 80 && commandPressed) {
                    // alert("Stampa disabilitata");
                    event.preventDefault();
                    event.stopPropagation();
                }
            }

            document.addEventListener("keydown", checkKeyPressed, false);
            document.addEventListener("keydown", handleKeydown, false);

            return () => {
                // Cleanup - remove event listeners when the component unmounts
                document.removeEventListener("keydown", checkKeyPressed, false);
                document.removeEventListener("keydown", handleKeydown, false);
            }
            // eslint-disable-next-line
        }, [isOpen, document.getElementById('zoomPdf'), detail]);




    const handleDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        pageRefs.current = Array(numPages).fill().map((_, i) => pageRefs.current[i] || React.createRef());
        setLoading(false);
    };

    useEffect(() => {
        if(viewerRef.current && viewerRef.current.clientWidth > 0 && isOpen && !isLoading){
            setScale(parseFloat(viewerRef.current.clientWidth / 1200) + 0.4);
        }
    }, [isOpen, isLoading]);


    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const newVisiblePages = entries
                .filter(entry => entry.isIntersecting)
                .map(entry => parseInt(entry.target.getAttribute('data-page-number')));
                if (newVisiblePages[0]) {
                    setCurrentPage(newVisiblePages[0]);
                    setVisiblePages(prevVisiblePages => [...new Set([...prevVisiblePages, ...newVisiblePages])]);


                    const links = document.querySelectorAll(`.linkAnnotation a`);
                    links.forEach(link => {
                        link.target = '_blank';
                    });
                }

        }, { threshold: 0 });


        pageRefs.current.forEach(ref => observer.observe(ref.current));

        return () => observer.disconnect();
    }, [numPages]);



    useEffect(() => {
        if(isOpen){
            setTimeout(() => {
                const links = document.querySelectorAll(`.linkAnnotation a`);
                links.forEach(link => {
                    link.target = '_blank';
                });
            }, 500);
        }
    }, [currentPage, visiblePages, isOpen]);


    useEffect(() => {
        const fetchPdf = async () => {
            try {
                const response = await fetch(url, {
                    headers: magazineId,
                });

                if (!response.ok) {
                    throw new Error('Errore durante il download del PDF');
                }

                const arrayBuffer = await response.arrayBuffer();
                setPdfData(arrayBuffer); // Salva il contenuto come ArrayBuffer
            } catch (error) {
                console.error('Errore durante il caricamento del PDF:', error);
            }
        };
        if(isOpen){
            fetchPdf();
        }
        // eslint-disable-next-line
    }, [url, isOpen]);




    const handleZoomIn = useCallback(() => {
        setScale(prevScale => prevScale + 0.1);
        setZoom(prev => prev + 10);
    }, []);


    const handleZoomOut = useCallback(() => {
        setScale(prevScale => prevScale - 0.1);
        setZoom(prev => prev - 10);
    }, []);


    const handleSetPage = (page) => {
        setCurrentPage(page);
        pageRefs.current[page - 1].current.scrollIntoView({ behavior: 'smooth' });
    };

    // eslint-disable-next-line
    let deviceIsMobile = false; //At the beginning we set this flag as false. If we can detect the device is a mobile device in the next line, then we set it as true.
    // eslint-disable-next-line
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        // eslint-disable-next-line
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
        // eslint-disable-next-line
        deviceIsMobile = true;
    }

    



    return (
        <>
            <SlideFull
                title={title}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                size={'max-w-7xl'}
                hasPadding={false}
                activeOverflow={true}
                extras={{
                    currentPage,
                    numPages,
                    scale
                }}
                setClean={() => {
                }}

            >

                {
                    (!deviceIsMobile && window.innerWidth > 800 && !isMobile && navigator.maxTouchPoints === 0) ? (
                          <>
                              <main className="items-center">
                                  <div className="xl:pl-60" >
                                      <div className="sticky top-0 z-40 bg-gray-900 flex justify-between shadow py-4 before:flex-1 after:flex-1">





                                          <div className="flex justify-between ">
                                              <div className="text-white">
                                                  Pagina {currentPage} di {numPages}
                                              </div>
                                          </div>


                                      </div>
                                      <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 w-full overflow-scroll h-screen" ref={viewerRef}>
                                          <div className="pdf-viewer mx-auto pb-60 blur-[5px] transition-all duration-300 hover:blur-none" id="zoomPdf">
                                              <div id={"zoomDiv"} style={{zoom: 1}}>

                                                  {/*
                                                  <Document file={url} onLoadSuccess={handleDocumentLoadSuccess}>
                                                      <Page pageNumber={currentPage} scale={scale} renderMode="canvas" />
                                                  </Document>
                                                  */}




                                                  <Document
                                                      // file={{
                                                      //     url: url,
                                                      //     httpHeaders: magazineId, // Aggiungi qui gli headers personalizzati
                                                      // }}
                                                      file={pdfData} // Passa l'ArrayBuffer
                                                      onLoadSuccess={handleDocumentLoadSuccess}
                                                  >
                                                      {Array.from(new Array(numPages), (el, index) => (
                                                          <div key={`page_${index + 1}`} ref={pageRefs.current[index]} data-page-number={index + 1} style={{ margin: '10px 0',  }}>
                                                              {visiblePages.includes(index + 1) ? (
                                                                  <Page pageNumber={index + 1}  scale={scale} renderMode="canvas" renderTextLayer={false} />
                                                              ) : (
                                                                  <div style={{ height: '900px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                     <LoaderWrapper isLoading={true} />
                                                                  </div>
                                                              )}
                                                          </div>
                                                      ))}
                                                  </Document>


                                              </div>
                                          </div>
                                      </div>

                                      <div className="sticky bottom-0 z-40  flex  justify-center py-4 ">


                                          <div className="flex items-center bg-gray-900 py-2 px-10 rounded-full">
                                              <button
                                                  onClick={handleZoomOut}
                                                  className="inline-flex mr-2 items-center rounded-md  px-3 py-2  text-white text-sm font-semibold "
                                              >
                                                  <MinusIcon className={'h-5 w-5 text-white'}/>
                                              </button>


                                              <button
                                                  className="inline-flex mr-2 items-center  text-white px-3 py-2  text-sm font-semibold  " >
                                                  {zoom}%
                                              </button>


                                              <button
                                                  onClick={handleZoomIn}
                                                  className="inline-flex mr-2 items-center rounded-md  px-3 py-2   text-sm font-semibold "
                                              >
                                                  <PlusIcon className={'h-5 w-5 text-white'} />
                                              </button>
                                          </div>

                                      </div>



                                  </div>
                              </main>


                              <aside className="fixed mt-20 inset-y-0  hidden w-60 overflow-y-auto border-r border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block">
                                  {
                                      thumbnails.map((thumbnail, index) => (
                                          <img alt={''} key={thumbnail.id} src={thumbnail.thumbnail.reference}
                                               className={ (currentPage ===  index + 1 ) ? "w-full my-3 cursor-pointer border border-red-600": "w-full my-3 cursor-pointer hover:border hover:border-red-600"}
                                               onClick={() => handleSetPage(index + 1)} />
                                      ))
                                  }
                              </aside>
                          </>
                    ) : (
                        <EmptyState
                            img={noMinigoal}
                            title={t("app.empty_state.mobile_hide_title")}
                            description={t("app.empty_state.mobile_hide_description")}
                        />
                    )
                }




            </SlideFull>


        </>

    );
};

export default PdfSliderViewer;
